global.$ = global.jQuery = require('jquery');
require('jquery-ui');
import 'jquery-ui/ui/widgets/datepicker.js';
require('./bootstrap');
// global.Chart = require('chart.js/dist/Chart.min.js');
// global.Chart = require('chart.js');

global.Chart = require('chart.js/auto').default;


// const Chart = require('chart.js');
// global.dt = require('datatables.net');
// require( 'datatables.net-responsive-dt' );
// require( 'datatables.net-bs' );
